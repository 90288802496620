import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_SET_LEVEL,
  USER_RESET,
} from "../actions/user";
import { AUTH_LOGOUT } from "../actions/auth";

import axios from "axios";

const state = {
  status: "",
  user: {},
  livelloLogin: "",
  loaded: false,
};

const getters = {
  loggedUser: (state) => state.user,
  user_loaded: (state) => state.loaded,
  livelloLogin: (state) => state.livelloLogin,
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    axios
      .get("Auth/Users/" + localStorage.getItem("DR_P-user-id"))
      .then((resp) => {
        commit(USER_SUCCESS, resp.data);
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });
  },
  [USER_SET_LEVEL]: ({ commit }, payload) => {
    commit(USER_SET_LEVEL, payload);
  },
  [USER_RESET]: ({ commit }) => {
    commit(USER_RESET);
  },
};

const mutations = {
  [USER_RESET]: (state) => {
    state.user = {};
  },
  [USER_SET_LEVEL]: (state, payload) => {
    state.livelloLogin = payload;
    state.user.livelloLogin = payload;
    localStorage.setItem("DR_P-livelloLogin", payload);
    localStorage.setItem("DR_P-user", JSON.stringify(state.user));
  },
  [USER_REQUEST]: (state) => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.user = resp;
    state.loaded = true;
  },
  [USER_ERROR]: (state) => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: (state) => {
    state.user = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
