<template>
  <div class="topbar d-flex align-items-center">
    <transition name="expand">
      <div v-if="showHamburger != 0" class="hamburger-wrapper">
        <i class="pi pi-bars hamburger-toggle" @click="onMenuToggle"></i>
      </div>
    </transition>
    <span>
      <span class="cursor-pointer" @click="$router.push('/')"><span class="fw-bold">DataRete</span><span
          class="fw-light"> Cloud</span></span></span>
    <div class="ms-auto d-flex align-items-center">


      <span class="me-2">Ciao,
        <strong>{{ loggedUser.id ? loggedUser.nome : "User" }}</strong>
      </span>

      <div class="d-flex mr-2">
        <div class="grid-center">
          <Avatar v-if="loggedUser.imgprofilo" :image="loggedUser.imgprofilo"></Avatar>
          <Avatar v-else :label="loggedUser.id ? loggedUser.nome.slice(0, 1) : 'U'"></Avatar>
        </div>
        <Tag v-if="loggedUser.livelloLogin" :value="loggedUser.livelloLogin"
          style="border-top-left-radius: 0; border-bottom-left-radius: 0"></Tag>
      </div>
      <!-- hola branch -->
      <Button class="p-button-sm p-button-secondary" style="max-height: 2rem" @click="logout">LogOut</Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_RESET } from "@/store/actions/user";
import Avatar from "primevue/avatar";
import AxiosService from "@/axiosServices/AxiosService";
import { useStore } from "vuex";
export default {
  props: {
    showHamburger: Number,
  },
  created() {
    const store = useStore();

    setTimeout(() => {
      this.userVoip = store.getters["loggedUser"];
    }, 1000);
  },
  data() {
    return {
      userVoip: null,
    };
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    logout: function () {
      this.$store.dispatch(USER_RESET);
      const service = new AxiosService("Auth/Logout");
      service.create();

      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("login");
      });
    },
  },
  computed: mapGetters(["loggedUser"]),
  components: { Avatar },
};
</script>

<style>
.hamburger-wrapper {
  overflow: hidden;
  width: 1.5rem;
  height: 100%;
  display: grid;
  place-content: center left;
}

.hamburger-toggle {
  cursor: pointer;
}

.expand-enter-active,
.expand-leave-active {
  transition-property: width, height;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
}

.expand-enter-from,
.expand-leave-to {
  width: 0px;
  height: 0px;
}
</style>
