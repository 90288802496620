<template>
  <div class="login-wrapper">
    <div v-if="mode == 'gestionale'" class="image"></div>
    <div v-else-if="mode == 'automotive'" class="image-automotive"></div>
    <div v-else class="image-crm"></div>
    <div class="form-signin">
      <form @submit.prevent="login">
        <img class="logo mb-3" :src="company.logo" />
        <h1 class="mb-5">{{ company.nome }}</h1>
        <h2>DataRete Posadas</h2>
        <h3>Accesso</h3>
        <span class="w-100 p-float-label mb-5">
          <InputText
            id="username"
            class="w-100"
            type="text"
            v-model="username"
            placeholder="Nome Utente"
          />
        </span>
        <span class="w-100 p-float-label mb-5">
          <Password
            id="password"
            class="w-100"
            v-model="password"
            :feedback="false"
            placeholder="Password"
            toggleMask
          />
        </span>
        <Button label="Entra" class="w-100" type="submit" />
      </form>
      <div v-if="accessoNonAbilitatoVisible">
        <Message severity="error">Accesso non abilitato</Message>
      </div>
      <span
        v-if="$store.getters.authStatus == 'error'"
        style="margin-top: 1rem; color: tomato"
        >Problema con l'autenticazione<br />Prova a reinserire i dati</span
      >
    </div>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService";
import { AUTH_REQUEST, AUTH_LOGOUT } from "../../store/actions/auth";
import { USER_REQUEST, USER_SET_LEVEL } from "@/store/actions/user";
import store from "../../store";
import { mapGetters } from "vuex";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      mode: "",
      accessoNonAbilitatoVisible: false,
    };
  },
  created() {
    const CURRENT_URL = window.location.href;
    if (CURRENT_URL.includes("prestitosi-gest")) {
      this.mode = "gestionale";
    } else if (CURRENT_URL.includes("prestitosi-automotive")) {
      this.mode = "automotive";
    } else {
      this.mode = "crm";
    }
  },
  methods: {
    login: function () {
      this.accessoNonAbilitatoVisible = false;
      const authObject = {
        username: this.username,
        password: this.password,
        portal: this.mode,
      };

      this.$store
        .dispatch(AUTH_REQUEST, authObject)
        .then((res) => {
          this.authStatus = store.getters.authStatus;
          if (res.data.ActionRequired == "ChangePassword") {
            this.$router.push("changepasswordrequest");
          }
          if (!res.data.Success) {
            this.accessoNonAbilitatoVisible = true;
            this.logout();
          } else {
            this.$router.push("setloginlevel");
          }
        })
        .catch(() => {
          this.authStatus = store.getters.authStatus;
        });
    },
    logout: function () {
      const service = new AxiosService("Auth/Logout");
      service.create();
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("login");
      });
    },
  },
  beforeMount() {
    if (store.getters.isAuthenticated) {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapGetters(["company"]),
  },
};
</script>
